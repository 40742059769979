<template>
    <section class="home">
        <header class="border-bottom pb-3 mb-3"><h1 class="fs-4">Dashboard</h1></header>

        <!--  -->
        <h2 class="fs-6" id="vendors">Vendors:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/vendors.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Search, edit and create your vendors that are on the device.</p>          
            </div>
          </div>
        <!--  -->
        <h2 class="fs-6" id="master-procurement-list">Master Procurement List:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/master-procurement-list.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Search, edit, and create Master Procurement lists. View Species/Cert/Grade, and buyer information.</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="log-sale">Log Sale:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/log-sale.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p></p>          
            </div>
          </div>
        <!--  -->
        <h2 class="fs-6" id="physical-inventory">Physical Inventory:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/physical-inventory.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Create Physical Inventory</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="scale-slips">Scale Slips:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/scale-slips.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Create Scale Slips</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="purchase-agreements">Purchase Agreements:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/purchase-agreements.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Create Purchase Agreements</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="price-lists">Price Lists:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/price-lists.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Create Price Lists</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="print-documents">Print Documents:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/print-documents.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Download PDF documents for printing or emailing.</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="export-data">Export Data:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/export-data.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Export data created on device to server.</p>          
            </div>
          </div>
          <!--  -->
        <h2 class="fs-6" id="unregister-device">Unregister Device:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/dashboard/unregister-device.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Clear the device to use it for another account.</p>          
            </div>
          </div>
    </section>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'Dashboard',
    components: {
    },
    mounted(){
      
    }
  }
  </script>
  