<template>
    <section class="home">
          <header class="border-bottom pb-3 mb-3"><h1 class="fs-4">Create Purchase Agreement</h1></header>
          <div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="method-one-tab" data-bs-toggle="pill" data-bs-target="#method-one" type="button" role="tab" aria-controls="pills-method-one" aria-selected="true">Method 1</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="method-two-tab" data-bs-toggle="pill" data-bs-target="#method-two" type="button" role="tab" aria-controls="pills-method-two" aria-selected="false">Method 2</button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="method-one" role="tabpanel" aria-labelledby="pills-method-one-tab">
                    
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step One:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method1/purchase-agreements.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Select "Purchase Agreements" from the dashboard.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Two:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method1/purchase-agreements-1.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Create a new purchase agreement,  or select an existing agreement.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Three:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method1/purchase-agreements-2.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Provide a Vendor from the drop down list, description, log rule, FOB, and signature name. Then select "Create"</p>          
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="method-two" role="tabpanel" aria-labelledby="pills-method-two-tab">
                    
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step One:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method2/vendors.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Select "Vendors" from the dashboard.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Two:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method2/step2.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Start typing the name of the vendor you want to look for and select.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Three:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method2/step3.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Select Purchase Agreements</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Four:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method2/step4.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Create a new purchase agreement,  or select an existing agreement.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Five:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                        <img src="@/assets/screenshots/create-purchase-agreement/method2/step5.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Provide a description, log rule, FOB, and signature name. Then select "Create"</p>        
                        </div>
                    </div>
                    <!--  -->
                </div>
            </div>
          </div>
      </section>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'CreatePurchaseAgreement',
    components: {
    }
  }
  </script>
  