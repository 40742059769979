<template>
    <section class="home">
          <header class="border-bottom pb-3 mb-3"><h1 class="fs-4">Create Purchase Agreement</h1></header>
          <div>
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="method-one-tab" data-bs-toggle="pill" data-bs-target="#method-one" type="button" role="tab" aria-controls="pills-method-one" aria-selected="true">Method 1</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="method-two-tab" data-bs-toggle="pill" data-bs-target="#method-two" type="button" role="tab" aria-controls="pills-method-two" aria-selected="false">Method 2</button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="method-one" role="tabpanel" aria-labelledby="pills-method-one-tab">
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step One:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method1/step1.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Select "Scale Slips" from the dashboard.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Two:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method1/step2.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Create a new scale slip, or select an existing scale slip.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Three:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method1/step3.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p></p>          
                            <ol>
                                <li>Provide a vendor from the drop down list.</li>
                                <li>Once selected, PA # and Price list will be populated with a list of of purchase agreements and price lists for that vendor.</li>
                                <li>If no purchase agreement or price list exists for this vendor, you can create a new one by clicking on "New Pa" or "New PL".</li>
                                <li>Once you have created a new purchase agreement or price list, you will be redirected back to the scale slip page.</li>
                                <li>Log rule will also auto-populate with the log rule you provided in your price list.</li>
                                <li>Select Prepay or %prepay.</li>
                            </ol>
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Four:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method1/step4.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Inv Loc will be auto populated based on the vendor you have selected. Inv Loc will will auto fill the Lat and Lon fields which can also be changed.</p>   
                            <p>Provide values for the rest of the fields.</p>       
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Five:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method1/step5.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Provide a comment if one is needed.</p>          
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="method-two" role="tabpanel" aria-labelledby="pills-method-two-tab">
                  <!--  -->
                  <h2 class="fs-6" id="vendors">Step One:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method2/vendors.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Select "Vendors" from the dashboard.</p>          
                        </div>
                    </div>
                    <!--  -->
                  <h2 class="fs-6" id="vendors">Step Two:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">/
                            <img src="@/assets/screenshots/create-scale-slip/method2/step2.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Start typing the name of the vendor you want to look for and select.</p>          
                        </div>
                    </div>
                    <!--  -->
                    <h2 class="fs-6" id="vendors">Step Three:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method2/step3.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p></p>          
                            <ol>
                                <li>Once selected, PA # and Price list will be populated with a list of of purchase agreements and price lists for that vendor.</li>
                                <li>If no purchase agreement or price list exists for this vendor, you can create a new one by clicking on "New Pa" or "New PL".</li>
                                <li>Once you have created a new purchase agreement or price list, you will be redirected back to the scale slip page.</li>
                                <li>Log rule will also auto-populate with the log rule you provided in your price list.</li>
                                <li>Select Prepay or %prepay.</li>
                            </ol>
                        </div>
                    </div>
                    <!--  -->
                  <h2 class="fs-6" id="vendors">Step Four:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method2/step4.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Start typing the name of the vendor you want to look for and select.</p>          
                        </div>
                    </div>
                    <!--  -->
                  <h2 class="fs-6" id="vendors">Step Five:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method2/step5.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Start typing the name of the vendor you want to look for and select.</p>          
                        </div>
                    </div>
                    <!--  -->
                  <h2 class="fs-6" id="vendors">Step Six:</h2>
                    <div class="row mb-3">
                        <div class="col-4 text-center">
                            <img src="@/assets/screenshots/create-scale-slip/method2/step6.png" alt="..." class="mw-100 mh-100 border border-2">
                        </div>
                        <div class="col-8">
                            <p>Provide a comment if one is needed.</p>          
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </section>
  </template>
  
  <script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  
  export default {
    name: 'CreateScaleslip',
    components: {
    }
  }
  </script>
  