import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Sidebar from '@/components/Sidebar.vue'

const app = createApp({...App,...{
    mounted(){
        const jQuery = $;
        $(document).on('click', 'aside > nav > .list-group >  .list-group-item > ul > li > a', (e)=>{
            $("html, body").animate({
                scrollTop: $('main #'+e.target.href.split('#')[1]).offset().top,
                easing:'linear'
            }, 0);
            e.stopPropagation();
            e.preventDefault();
        });
        // $("aside").sticky({topSpacing:0,});
        $.scrollUp();
    }
}}).use(store).use(router)
app.mount('#app');

console.log(app)
// app.config.globalProperties

