<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col">
        <div class="row">
          <aside class="top-0 col-3 border-end  pt-3 pb-3">
            <nav class="position-sticky top-0">
              <div class="list-group">
                <div :class="`${$route.name=='home'?'bg-primary text-white':'bg-light'} p-0 rounded-0 list-group-item`">
                  <router-link :class="` ${$route.name=='home'?'text-white':'text-black'} p-2 text-decoration-none d-block`" to="/">Register Device</router-link>
                </div>
                <div :class="`${$route.name=='home'?'bg-primary text-white':'bg-light'} p-0 rounded-0 list-group-item`">
                  <router-link :class="`${$route.name=='dashboard'?'bg-primary text-white':'bg-light'} rounded-0 list-group-item`" to="/dashboard">Dashboard</router-link>


                  <ul class="list-unstyled mb-0" v-if="$route.name=='dashboard'">
                    <li class=" border-top ps-4 bg-white">
                      <a class="text-decoration-none d-block"  href="#vendors">- Vendors</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#master-procurement-list">- Master Procurement List</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#log-sale">- Log Sale</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#physical-inventory">- Physical Inventory</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#scale-slips">- Scale Slips</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#purchase-agreements">- Purchase Agreements</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#price-lists">- Price Lists</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#print-documents">- Print Documents</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#export-data">- Export Data</a>
                    </li>
                    <li class="border-top border-bottom ps-4  bg-white">
                      <a class="text-decoration-none d-block" href="#unregister-device">- Unregister Device</a>
                    </li>
                  </ul>
                </div>
                <div :class="`${$route.name=='create-purchase-agreement'?'bg-primary text-white':'bg-light'} border rounded-0 p-0`"><router-link :class="`${$route.name=='create-purchase-agreement'?' text-white':'text-dark'} p-2 d-block text-decoration-none`" to="/create-purchase-agreement">Create Purchase Agreement</router-link></div>
                <div :class="`${$route.name=='create-scale-slip'?'bg-primary text-white':'bg-light'} border rounded-0 p-0`"><router-link :class="`${$route.name=='create-scale-slip'?' text-white':'text-dark'} p-2 d-block text-decoration-none`" to="/create-scale-slip">Create Scale Slip</router-link></div>
                <!-- <div :class="`border bg-light rounded-0 p-0`"><router-link class="p-2 d-block text-decoration-none text-dark" to="">Create Scale Slip</router-link></div> -->
              </div>
              <br />
              <div>
                
              </div>
              <!-- <router-link to="/">Home</router-link> | -->
              <!-- <router-link to="/about">About</router-link> -->
            </nav>
            
          </aside>
          <main class="col-9  pt-3 pb-3">
            <router-view/>
          </main>
        </div>
      </div>
    </div>
  </div>
  
</template>
<style lang="scss">
.container{
  max-width: initial !important;
  width: 960px !important;
}
#scrollUp{
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  background-color:rgba(#000,0.45);
  z-index:99;
  font-size: 0;
  width: 45px;
  height: 45px;
  display:flex;
  align-items:center;
  justify-content:Center;
  &:after{
    top: 5px;
    position:Relative;
    content: '';
    display:block;
    border-left: 4px solid #fff;
    border-top: 4px solid #fff;
    height: 50%;
    width: 50%;
    transform-origin: 50% 50%;
    transform: rotate(45deg);
  }
}
</style>
