<template>
  <section class="home">
        <header class="border-bottom pb-3 mb-3"><h1 class="fs-4">Register Device</h1></header>
        <div>
          <!--  -->
          <h2 class="fs-6" id="step-1">Step 1:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/register/Screen Shot 2023-12-07 at 10.04.31.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>When loading up a the device for the first time, the app will prompt you to register the device. Click on "Register Device".</p>          
            </div>
          </div>
          <!--  -->
          <h2 class="fs-6"  id="step-2">Step 2:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/register/Screen Shot 2023-12-07 at 10.04.42.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>Select the employee you want to use the device as and input the password.</p>          
            </div>
          </div>
          <!--  -->
          <h2 class="fs-6"  id="step-3">Step 3:</h2>
          <div class="row mb-3">
            <div class="col-4 text-center">
              <img src="@/assets/screenshots/register/Screen Shot 2023-12-07 at 10.14.07.png" alt="..." class="mw-100 mh-100 border border-2">
            </div>
            <div class="col-8">
              <p>The database will begin to download.</p>          
            </div>
          </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
